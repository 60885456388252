
.login-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/image/company-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
}

.header {
    padding: 20px;
}

.content {
    width: 960px;
    height: 520px;
    margin: 40px auto;
}

.el-input {
    width: 340px;
    border: 1px solid rgba(177, 179, 204, 1);
    border-radius: 4px
}

.login-form {
    width: 480px;
    height: 400px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 20px;

    ::v-deep .el-input__inner {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #DCDFE6;
        border-radius: 4px
    }

    ::v-deep .el-form-item__content {
        margin-left: 30px !important;
    }

    i {
        font-size: 20px;
    }
}

::v-deep .el-form-item {
    margin-bottom: 30px;
}

.el-form {
    background: #FFFFFF;
    margin-left: 35px;
}

.title {
    margin-bottom: 30px;
    text-align: center;
    padding-top: 16px;
    margin-right: 50px;
}

.title span {
    font-size: 30px;
    color: #5794FF;
    margin-left: 30px;
}

.title p {
    color: #333333;
    font-size: 18px;
    font-weight: 400;
    margin-left: 30px;
}

.admin-btn {
    width: 340px;
    /*margin-left: 68px;*/
    margin-top: 30px;
    background: rgba(87, 148, 255, 1);
    border-radius: 20px;
    border: none;
    margin-left: 30px;
}
